import anime from "animejs";

export default class SwmSlider {
    constructor(sliderObj) {
        const ANIMATION_DURATION = 6000;
        let currentSlide = 0;
        const slides = sliderObj.querySelectorAll(".slider__slide");
        const controlsWrapper = sliderObj.querySelector(".slider__controls");
        const controls = [];

        this.playNextSlide = () => {
            slides[currentSlide].animation.restart();
        }

        this.goToSlide = (i) => {
            if (i === currentSlide) return;

            slides[currentSlide].fadeOut(() => {
                currentSlide = i;
                this.playNextSlide();
            })
        }

        for (let i = 0; i < slides.length; i++) {
            const n = i;
            const slide = slides[n];
            const slideImage = slide.querySelector(".slider__image");
            const slideContent = slide.querySelector(".slider__content");
            const slideHeadline = slide.querySelector(".slider__headline");
            const slideSubheadline = slide.querySelector(".slider__subheadline");
            const slideButton = slide.querySelector(".slider__button");

            const slideHeadlineAnim = slide.querySelector(".slider__headlineAnim");
            const slideSubheadlineAnim = slide.querySelector(".slider__subheadlineAnim");
            const slideButtonAnim = slide.querySelector(".slider__buttonAnim");

            const slideContentAnim = slide.querySelector(".slider__contentAnim")

            // configure mouseMove animation

            let wait = false;

            if (window.innerWidth > 720) {

                sliderObj.addEventListener("mousemove", (e) => {
                    if (!wait) {
                        wait = true;
                        const x = -1 * ((e.clientX - window.innerWidth / 2) / window.innerHeight);
                        const y = -1 * ((e.clientY - window.innerHeight / 2) / window.innerHeight);

                        slideHeadlineAnim.style.transform = `translate3d(${40 * x}px, ${40 * y}px, 0)`;
                        slideSubheadlineAnim.style.transform = `translate3d(${55 * x}px, ${55 * y}px, 0)`;
                        slideButtonAnim.style.transform = `translate3d(${40 * x}px, ${40 * y}px, 0)`;

                        // slideContentAnim.style.transform = `rotateX(${y * 30}deg) rotateY(${x * -30}deg)`;
                        slideContentAnim.style.transform = `rotate3d(${y}, ${-x}, 0, ${Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)) * 20}deg)`;
                        setTimeout(() => {
                            wait = false;
                        }, 100)
                    }
                })
            }

            // configure control
            controls[i] = document.createElement("div");
            controls[i].className = "slider__control";
            controls[i].textContent = i + 1;

            controlsWrapper.append(controls[i]);
            controls[i].addEventListener("click", () => {
                this.goToSlide(i);
            });

            // configure animation



            slide.animation = anime.timeline({
                autoplay: false
            }).add({
                targets: slide,
                opacity: [0, 1],
                easing: "easeOutSine",
                duration: 1000,
                begin: () => {
                    slide.style.opacity = 0;
                    slide.style.display = "block";
                    controls[i].classList.add("active");
                }
            }).add({
                targets: slideImage,
                duration: ANIMATION_DURATION,
                easing: "linear",
                scale: [1, 1.05]
            }, 0).add({
                targets: [slideHeadline, slideSubheadline, slideButton],
                opacity: [0, 1],
                translateY: [20, 0],
                duration: 1400,
                easing: "easeOutQuart",
                delay: anime.stagger(400)
            }, 400).add({
                targets: slideContent,
                scale: 0.8,
                duration: 800,
                easing: "easeOutQuart",
            }, ANIMATION_DURATION - 800).add({
                targets: slide,
                opacity: 0,
                easing: "easeOutSine",
                duration: 800,
                complete: () => {
                    slide.style.display = "none";
                    controls[i].classList.remove("active");
                    currentSlide = currentSlide < slides.length - 1 ? currentSlide + 1 : 0;
                    this.playNextSlide();
                }
            }, ANIMATION_DURATION - 800);

            slide.fadeOut = (callback) => {

                slide.animation.pause();
                anime({
                    targets: slide,
                    opacity: 0,
                    easing: "easeOutSine",
                    duration: 300,
                    complete: () => {

                        slide.style.display = "none";
                        controls[i].classList.remove("active");
                        callback();
                    }
                })
            }


        }

        slides[currentSlide].animation.restart();


    }

}