import Slider from "./components/slider";
import CategorySelect from "./components/categorySelect";
import "./components/swmMovies";



(function () {
    const slider = new Slider(document.getElementById("homeSlider"));

    const sampleResults = `
<a href="#" class="articlePreview articlePreview--large">
	<div class="articlePreview__imageWrapper">
		<img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
	</div>
	<div class="articlePreview__content">
		<div class="articlePreview__categories">

							<span onclick="swmGoTo(event, '#')" class="articlePreview__categoryLink">blog</span>
							<span onclick="swmGoTo(event, '#')" class="articlePreview__categoryLink">boliwia</span>
							<span onclick="swmGoTo(event, '#')" class="articlePreview__categoryLink">misje</span>
					</div>

		<h3 class="articlePreview__title">home.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
		<div class="articlePreview__excerpt">Wypełniając poniższy formularz, decydujesz o&nbsp;kraju, kwocie i&nbsp;okresie przez jaki chcesz wspierać dzieci. Wraz z&nbsp;otrz...</div>

		<div class="articlePreview__date">
			22 kwietnia 2020
		</div>
	</div>
</a>
                <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">home.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">home.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">home.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">home.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">home.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">home.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">home.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">home.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">home.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    `



    const resultsContainer = document.getElementById("newsResults");
    const placeholder = document.getElementById("newsPlaceholder");
    const categoriesBar = document.getElementById("newsCategoriesSelectDesktop");
    const categoriesMobileBar = document.getElementById("newsCategoriesSelectMobile");

    const handleCategorySelectFn = function (categoryName) {
        console.log(categoryName);
        resultsContainer.innerHTML = sampleResults;
    }

    const newsCategorySelect = new CategorySelect({
        resultsContainer: resultsContainer,
        categoriesBar: categoriesBar,
        categoriesMobileBar: categoriesMobileBar,
        placeholder: placeholder,
        handleCategorySelectFn: handleCategorySelectFn,
        loadMore: false
    });

})()