(function () {
    const moviesWrappers = document.querySelectorAll(".movies");

    for (let i = 0; i < moviesWrappers.length; i++) {
        const wrapper = moviesWrappers[i];
        const iframe = wrapper.querySelector(".movies__iframe");
        const items = wrapper.querySelectorAll(".movies__listItem");

        for (let n = 0; n < items.length; n++) {
            const item = items[n];
            item.addEventListener('click', () => {
                iframe.setAttribute("src", item.getAttribute("data-movie"));
                wrapper.querySelector(".movies__listItem--active").classList.remove("movies__listItem--active");
                item.classList.add("movies__listItem--active");
            });
        }
    }
})();